<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogVisible"
    width="605px"
    @close="dialogReset">
    <div v-if="type===3||type===4||type===8">
      <el-alert
        style="width: 250px;margin-bottom: 15px"
        :title="type===3?'选中为启用，未选中为不启用':(type===4?'选中为反逻辑，未选中为正逻辑':'选中为上传，未选中为不上传')"
        type="warning"
        :closable="false">
      </el-alert>
      <el-checkbox-group v-model="switchBitsCheck">
        <template v-for="(item,index) in switchBits">
          <el-checkbox :key="index" border :label="index" style="margin-right: 0;margin-bottom: 5px">
            {{item}}
          </el-checkbox>
          <br v-if="item===0" :key="item"/>
        </template>
      </el-checkbox-group>
    </div>
    <el-date-picker
      v-else-if="type===5"
      v-model="value"
      value-format="yy,MM,dd,HH,mm,ss"
      type="datetime"
      placeholder="选择日期时间"></el-date-picker>
    <div v-else-if="type===7" style="display: flex;">
      <el-input v-model="host.value1" placeholder="基站1"></el-input>
      <el-input v-model="host.value2" placeholder="基站2" style="margin-left: 10px"></el-input>
      <el-input v-model="host.value3" placeholder="基站3" style="margin-left: 10px"></el-input>
      <el-input v-model="host.value4" placeholder="基站4" style="margin-left: 10px"></el-input>
      <el-input v-model="host.port" placeholder="备用" style="margin-left: 10px"></el-input>
    </div>
    <el-input
      v-else-if="type===1"
      v-model="value"
      placeholder="楼层数，2-60"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" :loading="sureLoading" @click="sure">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  export default {
    /* eslint-disable */
    props: ["dtuCode", "nodeCode", "protoType"],
    /* eslint-enable */
    data() {
      return {
        dialogVisible: false,
        sureLoading: false,
        title: "提示",
        type: 0,
        switchBitsCheck: [],
        switchBits: [7, 6, 5, 4, 3, 2, 1, 0, 7, 6, 5, 4, 3, 2, 1, 0, 7, 6, 5, 4, 3, 2, 1, 0, 7, 6, 5, 4, 3, 2, 1, 0],
        host: {
          value1: "",
          value2: "",
          value3: "",
          value4: "",
          port: "",
        },
        value: "",
        haOrHf: null,
      };
    },
    methods: {
      open(type, haOrHf) {
        this.dialogVisible = true;
        this.type = type;
        this.haOrHf = haOrHf;
        switch (type) {
          case 1:
            this.title = "设置楼层数";
            break;
          case 3:
            this.title = "设置信号开关(32路)";
            this.switchBitsCheck = haOrHf.dataSwitch.length === 64 ? this.getBit1Index(haOrHf.dataSwitch) : [];
            break;
          case 4:
            this.title = "设置信号逻辑(32路)";
            this.switchBitsCheck = haOrHf.dataLogic.length === 64 ? this.getBit1Index(haOrHf.dataLogic) : [];
            break;
          case 5:
            this.title = "修改采集盒时间";
            break;
          case 7:
            this.title = "设返层基站(最多4个，填0为不启用)";
            break;
          case 8:
            this.title = "设置IO屏蔽字(32路)";
            this.switchBitsCheck = haOrHf.dataSwitch.length === 64 ? this.getBit1Index(haOrHf.dataSwitch) : [];
            break;
        }
      },
      sure() {
        switch (this.type) {
          case 3:
          case 4:
            let temp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            this.switchBitsCheck.forEach(item => {
              temp[item] = 1;
            });
            this.value = temp.join("");
            break;
          case 7:
            this.value = `${this.host.value1},${this.host.value2},${this.host.value3},${this.host.value4},${this.host.port}`;
            break;
          case 8:
            let dataSwitch = this.haOrHf.dataSwitch;
            if (dataSwitch && dataSwitch.length === 64) {
              let temp8 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              this.switchBitsCheck.forEach(item => {
                if (item < 32 && dataSwitch[item] === "1") {
                  temp8[item] = 1;
                }
              });
              this.value = temp8.join("");
              break;
            } else {
              this.$message.error("设置失败：未接收到心跳数据，无法计算值");
              return;
            }
        }
        if (!this.value) {
          this.$message.error("值不能为空");
          return;
        }
        this.$confirm(`确定${this.title}吗?`, "提示").then(() => {
          this.sureLoading = true;
          this.$api.getData("remote/sendHDOrHI", {
            dtuCode: this.dtuCode,
            nodeCode: this.nodeCode,
            protoType: this.protoType,
            commandType: this.type,
            value: this.value,
          }).then(() => {
            this.$message.success("命令发送成功");
            this.sureLoading = false;
            this.dialogVisible = false;
          }).catch(_ => {
            this.sureLoading = false;
          });
        });
      },
      dialogReset() {
        this.value = "";
      },
      getBit1Index(arr) {
        let temp = [];
        arr.forEach((item, index) => {
          if (item === "1") {
            temp.push(index);
          }
        });
        return temp;
      },
    },
  };
</script>
