<template>
  <div class="monitor-single">
    <div class="em-wrapper">
      <div class="em-left">
        <div class="em-title">
          {{elevator.name}}
          <span style="float: right">{{ha.speed}}m/s</span>
        </div>
        <lift-sensor-monitor-car
          :reg-code="elevator.regNo"
          @status-change="onStatusChange"></lift-sensor-monitor-car>
      </div>
      <div class="em-right">
        <div class="em-base-info">
          <div class="em-base-info-left">
            <div class="em-floor">{{hb.s_floor_show || hb.s_floor_real}}</div>
            <div class="em-direction">
              <i v-if="hb.s_direction===1" class="iconfont iot-up" :class="{'direction-animate':hb.s_run===1}"></i>
              <i
                v-else-if="hb.s_direction===2"
                class="iconfont iot-down"
                :class="{'direction-animate-reverse':hb.s_run===1}"></i>
            </div>
          </div>
          <table class="em-base-info-right">
            <tr>
              <th>电梯编号:</th>
              <td>{{elevator.no}}</td>
              <th>设备代码:</th>
              <td>{{elevator.regNo}}</td>
            </tr>
            <tr>
              <th>DTU编号:</th>
              <td>{{elevator.dtuCode}}</td>
              <th>节点:</th>
              <td>{{elevator.nodeCode}}</td>
            </tr>
            <tr>
              <th>电梯名称:</th>
              <td>{{elevator.name}}</td>
              <th>使用单位:</th>
              <td>{{elevator.useUnitName}}</td>
            </tr>
            <tr>
              <th>维保企业:</th>
              <td colspan="3">{{elevator.maintComName}}</td>
            </tr>
          </table>
        </div>
        <el-tabs
          value="tabInfo"
          class="tab"
          type="border-card"
          style="padding-bottom: 10px"
          @tab-click="onTabClick">
          <el-tab-pane label="基本状态" name="tabInfo">
            <table class="vm-table" style="margin-top: 7px">
              <tr>
                <th colspan="6"><span class="title-icon"></span>电梯状态</th>
              </tr>
              <tr>
                <td>
                  <i class="iconfont iot-service"></i>
                  <div class="cell-title">当前服务模式</div>
                  <div class="cell-value">{{hb.s_mode}}</div>
                </td>
                <td>
                  <i class="iconfont iot-car"></i>
                  <div class="cell-title">轿厢运行状态</div>
                  <div class="cell-value">{{hb.s_run_desc}}</div>
                </td>
                <td>
                  <i class="iconfont iot-lock"></i>
                  <div class="cell-title">开锁区域</div>
                  <div class="cell-value">{{hb.s_unlock}}</div>
                </td>
                <td>
                  <i class="iconfont iot-door"></i>
                  <div class="cell-title">轿门状态</div>
                  <div class="cell-value">{{hb.s_door1}}</div>
                </td>
                <td>
                  <i class="iconfont iot-door-lock"></i>
                  <div class="cell-title">关门到位</div>
                  <div class="cell-value">{{hb.s_close}}</div>
                </td>
                <td>
                  <i class="iconfont iot-people"></i>
                  <div class="cell-title">检修传感</div>
                  <div class="cell-value">{{hb.s_anybody}}</div>
                </td>
              </tr>
            </table>
            <table class="vm-table single-table" style="margin-top: 20px">
              <tr>
                <th colspan="8"><span class="title-icon"></span>轿顶信号</th>
              </tr>
              <tr>
                <td :class="getTdClass(0)">轿门安全</td>
                <td :class="getTdClass(1)">开门到位</td>
                <td :class="getTdClass(2)">报警按钮</td>
                <td :class="getTdClass(3)">人感检测</td>
                <td :class="getTdClass(4)">轿顶检修</td>
                <td :class="getTdClass(5)">关门到位</td>
                <td :class="getTdClass(6)">下平层1</td>
                <td :class="getTdClass(7)">上平层1</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td :class="getTdClass(8+7)">消防员运</td>
              </tr>
            </table>
            <table class="vm-table single-table" style="margin-top: 20px">
              <tr>
                <th colspan="8"><span class="title-icon"></span>机房信号</th>
              </tr>
              <tr>
                <td></td>
                <td :class="getTdClass(16+1)">消防返回</td>
                <td :class="getTdClass(16+2)">机房检修</td>
                <td :class="getTdClass(16+3)">运行控制</td>
                <td :class="getTdClass(16+4)">运行反馈</td>
                <td :class="getTdClass(16+5)">抱闸控制</td>
                <td :class="getTdClass(16+6)">抱闸反馈</td>
                <td :class="getTdClass(16+7)">厅门安回</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </el-tab-pane>
          <el-tab-pane label="电梯故障" name="tabFault">
            <table class="vm-table" style="margin-top: 7px">
              <tr>
                <td :class="{'selected':!hb.faultCodes||!hb.faultCodes.length}">电梯无故障</td>
                <td :class="{'selected':hasFault(2)}">运行时安全回路断路</td>
                <td :class="{'selected':hasFault(5)}">电梯超速</td>
                <td :class="{'selected':hasFault(6)}">主电源故障</td>
              </tr>
              <tr>
                <td :class="{'selected':hasFault(7)}">冲顶故障</td>
                <td :class="{'selected':hasFault(8)}">蹲底故障</td>
                <td :class="{'selected':hasFault(9)}">运行中开门故障</td>
                <td :class="{'selected':hasFault(10)}">开门走车故障</td>
              </tr>
              <tr>
                <td :class="{'selected':hasFault(11)}">超时停靠不关门</td>
                <td :class="{'selected':hasFault(13)}">非平层停车故障</td>
                <td :class="{'selected':hasFault(3)}">安回闭合后超时未运行</td>
                <td :class="{'selected':hasFault(12)}">开门过程超时</td>
              </tr>
              <tr>
                <td :class="{'selected':hasFault(4)}">关门受阻超时超次</td>
                <td :class="{'selected':hasFault(14)}">电动机运转时间限制器动作</td>
                <td :class="{'selected':hasFault(15)}">反复开关门停梯</td>
                <td :class="{'selected':hasFault(16)}">关门过程超时</td>
              </tr>
              <tr>
                <td :class="{'selected':hasFault(17)}">开门未遂故障</td>
                <td :class="{'selected':hasFault(18)}">返基站不开门</td>
                <td :class="{'selected':hasFault(19)}">非基站超时不开门</td>
                <td></td>
              </tr>
            </table>
          </el-tab-pane>
          <el-tab-pane label="故障记录" name="tabFaultList">
            <el-button
              :loading="fault.loading"
              style="margin-bottom: 10px"
              type="primary"
              icon="el-icon-refresh"
              @click="getFaultList(1)">
              刷新
            </el-button>
            <vm-table-new
              ref="faultTable"
              :filter.sync="faultSearch"
              url="sensorFaults">
              <el-table-column prop="code" label="故障代码" align="center" width="100px"></el-table-column>
              <el-table-column label="故障名称">
                <template slot-scope="scope">
                  {{sensorFault[scope.row.code] || "未知"}}
                </template>
              </el-table-column>
              <el-table-column prop="floor" label="楼层" align="center" width="100px"></el-table-column>
              <el-table-column prop="createTime" label="发生时间"></el-table-column>
            </vm-table-new>
          </el-tab-pane>
          <el-tab-pane label="事件记录" name="tabEvent">
            <el-button
              :loading="event.loading"
              style="margin-bottom: 10px"
              type="primary"
              icon="el-icon-refresh"
              @click="getEventList(1)">
              刷新
            </el-button>
            <vm-table-new
              ref="eventTable"
              :filter.sync="eventSearch"
              url="sensorEvents">
              <el-table-column prop="code" label="事件代码" align="center" width="100px"></el-table-column>
              <el-table-column label="事件名称">
                <template slot-scope="scope">
                  {{sensorEvent[scope.row.code] || "未知"}}
                </template>
              </el-table-column>
              <el-table-column prop="floor" label="楼层" align="center" width="100px"></el-table-column>
              <el-table-column prop="createTime" label="发生时间"></el-table-column>
            </vm-table-new>
          </el-tab-pane>
          <el-tab-pane label="电梯设置" name="tabSetting">
            <div class="setting">
              <div style="text-align: center">
                <el-button style="width: 135px" @click="set(5)">修改采集盒时间</el-button>
                <el-button style="width: 135px" @click="set(3)">设置信号开关(32路)</el-button>
                <el-button style="width: 135px" @click="set(201)">打开检验模式</el-button>
                <el-button style="width: 135px" @click="set(1)">设置楼层数</el-button>
              </div>
              <div style="text-align: center;margin-top: 10px">
                <el-button style="width: 135px" @click="set(6)">重启采集盒</el-button>
                <el-button style="width: 135px" @click="set(4)">设置信号逻辑(32路)</el-button>
                <el-button style="width: 135px" @click="set(200)">关闭检验模式</el-button>
                <el-button style="width: 135px" @click="set(7)">设返层基站(物理楼层)</el-button>
              </div>
              <div style="text-align: center;margin-top: 10px">
                <el-button style="width: 135px" @click="set(8)">设置IO屏蔽字(32路)</el-button>
                <div style="width: 140px;display: inline-block"></div>
                <div style="width: 140px;display: inline-block"></div>
                <div style="width: 140px;display: inline-block"></div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="统计数据" name="tabStats">
            <el-button

              type="primary"
              @click="sendHC()">
              发送查询命令
            </el-button>
            <table class="vm-table" style="margin-top: 8px">
              <tr>
                <th>设备累计上电时间</th>
                <td>{{hc.runningTime}}分钟</td>
              </tr>
              <tr>
                <th>设备累计运行次数</th>
                <td>{{hc.runningCount}}次</td>
              </tr>
              <tr>
                <th>设备开门次数</th>
                <td>{{hc.doorOpenCount}}次</td>
              </tr>
              <tr>
                <th>钢丝绳(带)折弯次数</th>
                <td>{{hc.bendCount}}次</td>
              </tr>
              <tr>
                <th>设备累计运行跨层数</th>
                <td>{{hc.runningDistance}}</td>
              </tr>
            </table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <cmd-h-d ref="cmdHD" :dtu-code="elevator.dtuCode" :node-code="elevator.nodeCode" proto-type="HD"></cmd-h-d>
  </div>
</template>
<script>
  import VmTableNew from "@/components/refactor/VmTable";
  import LiftSensorMonitorCar from "./LiftSensorMonitorCar";
  import CmdHD from "./CmdHD";

  //当前服务模式
  const s_mode = {
    0: "未知",
    1: "正常运行",
    2: "停止服务",
    3: "检修服务",
    4: "消防返回",
    5: "消防员运行",
    6: "应急电源运行",
    7: "地震模式",
    8: "检验模式",
  };

  //轿厢运行状态
  const s_run = {
    0: "停止",
    1: "运行",
  };

  //开锁区域
  const s_unlock = {
    0: "非门区",
    1: "上门区",
    2: "下门区",
    3: "门区",
  };

  //轿门状态
  const s_door1 = {
    0: "未知",
    1: "正在关门",
    2: "关门到位",
    3: "正在开门",
    4: "开门到位",
    5: "门锁锁止",
    6: "保持不完全关闭",
  };

  //关门到位
  const s_close = {
    0: "关门未到位",
    1: "关门已到位",
  };

  //轿内是否有人
  const s_anybody = {
    0: "启用",
    1: "屏蔽",
  };

  export default {
    components: {LiftSensorMonitorCar, CmdHD, VmTableNew},
    data() {
      return {
        elevator: {
          name: "---",
        },
        ha: {
          dataSwitch: [],
          dataLogic: [],
          signalStatus: [],
          speed: "--",
        },
        eventSearch: {
          elevatorId: 0,
        },
        faultSearch: {
          elevatorId: 0,
        },
        hb: {
          s_mode: "--",
          s_run: "--",
          s_run_desc: "--",
          s_unlock: "--",
          s_door1: "--",
          s_close: "--",
          s_anybody: "--",
          s_floor_real: "--",
          s_floor_show: "--",
          s_direction: 0, //0：无方向 1：上行 2：下行
          faultCodes: [],
          eventCodes: [],
        },
        hc: {
          runningTime: 0,
          runningCount: 0,
          doorOpenCount: 0,
          bendCount: 0,
          runningDistance: 0,
        },
        fault: {
          data: [],
          loading: false,
          currentPage: 1,
          total: 0,
        },
        event: {
          data: [],
          loading: false,
          currentPage: 1,
          total: 0,
        },
        sensorEvent: {
          1: "电梯恢复自动运行模式",
          2: "主电源断电",
          3: "进入停止服务",
          4: "进入检修运行模式",
          5: "当前服务模式-未知",
          6: "开始运行",
          7: "结束运行",
          8: "发生过故障且长时间显示平层",
          9: "报警按钮动作",
        },
        sensorFault: {
          2: "运行时安全回路断路",
          3: "安回闭合后超时未运行",
          4: "关门受阻超时超次",
          5: "电梯超速",
          6: "主电源故障",
          7: "冲顶故障",
          8: "蹲底故障",
          9: "运行中开门故障",
          10: "开门走车故障",
          11: "超时停靠不关门",
          12: "开门过程超时",
          13: "非平层停车故障",
          14: "电动机运转时间限制器动作",
          15: "反复开关门停梯",
          16: "关门过程超时",
          17: "开门未遂故障",
          18: "返基站不开门",
          19: "非基站超时不开门",
        },
      };
    },
    computed: {},
    methods: {
      init(data) {
        document.title = this.$t("route.singleMonitor");
        this.elevator = data.elevator;
        this.getEventList();
        this.getFaultList();
      },
      getEventList(pageIndex) {
        this.eventSearch.elevatorId = this.elevator.id;
        this.$refs.eventTable.getList(pageIndex);
      },
      getFaultList(pageIndex) {
        this.faultSearch.elevatorId = this.elevator.id;
        this.$refs.faultTable.getList(pageIndex);
      },
      onStatusChange(proto) {
        switch (proto.t) {
          case "HA":
            this.ha.dataSwitch = [...proto.d.dataSwitch];
            this.ha.dataLogic = [...proto.d.dataLogic];
            this.ha.signalStatus = [...proto.d.signalStatus];
            this.ha.speed = proto.d.speed;
            break;
          case "HB":
            this.hb = JSON.parse(JSON.stringify(proto.d));
            let data = proto.d;
            this.hb.s_mode = s_mode[data.s_mode] || "--";
            this.hb.s_run_desc = s_run[data.s_run] || "--";
            this.hb.s_run = data.s_run;
            this.hb.s_unlock = s_unlock[data.s_unlock] || "--";
            this.hb.s_door1 = s_door1[data.s_door1] || "--";
            this.hb.s_close = s_close[data.s_close] || "--";
            this.hb.s_anybody = s_anybody[data.s_anybody] || "--";
            break;
          case "HC":
            this.hc = proto.d;
            break;
        }
      },
      hasFault(faultCode) {
        return this.hb.faultCodes.indexOf(faultCode) !== -1;
      },
      sendHC() {
        this.$api.getData("remote/sendHC", {
          dtuCode: this.elevator.dtuCode,
          nodeCode: this.elevator.nodeCode,
        }).then(response => {
          this.$message.success("查询命令发送成功");
        });
      },
      onTabClick(tab) {
        if (tab.name === "tabStats") {
          this.sendHC();
        }
      },
      set(value) {
        let param = {
          dtuCode: this.elevator.dtuCode,
          nodeCode: this.elevator.nodeCode,
          protoType: "HD",
        };
        switch (value) {
          case 201:
          case 200:
            this.$confirm(`确定${value === 201 ? "打开" : "关闭"}检验模式吗?`, "提示").then(() => {
              param.commandType = 2;
              param.value = (value === 201 ? 1 : 0);
              this.$api.getData("remote/sendHDOrHI", param).then(() => {
                this.$message.success("命令发送成功");
              });
            });
            break;
          case 6:
            this.$confirm("确定重启采集盒吗?", "提示").then(() => {
              param.commandType = 6;
              this.$api.getData("remote/sendHDOrHI", param).then(() => {
                this.$message.success("命令发送成功");
              });
            });
            break;
          case 1:
          case 3:
          case 4:
          case 5:
          case 7:
          case 8:
            this.$refs.cmdHD.open(value, this.ha);
            break;
        }
      },
      getTdClass(index) {
        if (this.ha.dataSwitch[index] === "0") {
          return "gray";
        }
        return this.ha.signalStatus[index] === "1" ? "green" : "blue";
      },
    },
  };
</script>
<style lang="scss">
.monitor-single {
  background-color: black;
  width: 100%;
  height: 100vh;
  min-height: 700px;
  padding-top: 4vh;
  box-sizing: border-box;

  .em-wrapper {
    height: 632px;
    width: 1080px;
    margin: 0 auto;
    display: flex;
  }

  .em-left {
    .em-title {
      background-color: #fcfcfc;
      padding: 14px 15px;
    }
  }

  .em-right {
    flex: 1;
    background: #f1f1f1;
    z-index: 1;

    .em-base-info {
      background-color: #fff;
      position: relative;
      height: 130px;

      .em-base-info-left {
        position: absolute;
        top: -90px;
        width: 100px;
        height: 0;
        border: 90px solid transparent;
        border-left: none;
        border-bottom: 100px solid #0288D1;
        color: white;
        text-align: center;

        .em-floor {
          font-size: 62px;
          margin-top: 10px;
        }

        .em-direction {
          position: absolute;
          width: 50px;
          height: 100px;
          left: 100px;
          top: 0;
          overflow: hidden;

          .iot-up, .iot-down {
            position: absolute;
            font-size: 50px;
            top: 40px;
            left: 0;
          }

          .direction-animate {
            top: 0;
            left: 0;
            animation: direction 0.9s linear infinite;
            -moz-animation: direction 0.9s linear infinite;
          }

          .direction-animate-reverse {
            top: 0;
            left: 0;
            animation: direction-reverse 0.9s linear infinite;
            -moz-animation: direction-reverse 0.9s linear infinite;
          }

          @keyframes direction {
            0% {
              transform: translate(0px, 80px);
            }
            100% {
              transform: translate(0px, -60px);
            }
          }

          @keyframes direction-reverse {
            0% {
              transform: translate(0px, -60px);
            }
            100% {
              transform: translate(0px, 80px);
            }
          }
        }
      }

      .em-base-info-right {
        margin-left: 200px;
        font-size: 13px;
        color: #555;
        padding: 20px 10px 0 10px;

        td {
          max-width: 138px;
          min-width: 110px;
          padding: 4px 2px;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        th {
          padding: 4px 2px;
          font-weight: normal;
          color: #333;
          white-space: nowrap;
        }
      }
    }

    .setting {
      background-color: white;
      padding: 11px 0;
      margin-top: 7px;
    }
  }

  .title-icon {
    position: absolute;
    background-color: #0288D1;
    border-radius: 5px;
    height: 5px;
    width: 5px;
    left: 10px;
    top: 13px;
  }

  .iconfont {
    font-size: 25px;
  }

  .cell-title {
    margin-top: 4px;
  }

  .cell-value {
    display: inline-block;
    margin-top: 8px;
    padding: 3px 5px;
    background-color: #03A9F4;
    color: white;
    border-radius: 1px;
    font-size: 11px;
  }

  //普通table使用
  table.vm-table {
    width: 100%;
    font-size: 13px;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: unset;
    border-top: #eee 1px solid;
    border-right: #eee 1px solid;

    th, td {
      border-left: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }

    th {
      color: #555;
      padding: 9px 20px;
      background-color: #fff;
      text-align: left;
      font-weight: normal;
      position: relative;
    }

    td {
      color: #666;
      padding: 11px 5px;
      background-color: #ffffff;
      text-align: center;
    }

    td.selected {
      background-color: #E91E63;
      color: white
    }
  }

  table.single-table {
    td {
      background-color: #ddd;
      height: 15px;
    }

    td.blue {
      background-color: #409EFF;
      color: white
    }

    td.green {
      background-color: #67C23A;
      color: white
    }
  }
}
</style>
