<template>
  <el-drawer
    title="ITE IOT"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    size="600px">
    <table class="vm-table" style="margin:0 auto;">
      <tr>
        <td>
          D1 3 Phase Supply:
          <img v-if="ibe.D1" class="image_light"/><img v-if="!ibe.D1&&ibe.D1!==null" class="image_offlight"/>
        </td>
        <td>
          D2 Single Phase Supply:
          <img v-if="ibe.D2" class="image_light"/><img v-if="!ibe.D2&&ibe.D2!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          D3 RCD:
          <img v-if="ibe.D3" class="image_light"/><img v-if="!ibe.D3&&ibe.D3!==null" class="image_offlight"/>
        </td>
        <td>
          D4 AC Supply:
          <img v-if="ibe.D4" class="image_light"/><img v-if="!ibe.D4&&ibe.D4!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          D5 DC Supply:
          <img v-if="ibe.D5" class="image_light"/><img v-if="!ibe.D5&&ibe.D5!==null" class="image_offlight"/>
        </td>
        <td>
          D6 Door Supply Voltage:
          <img v-if="ibe.D6" class="image_light"/><img v-if="!ibe.D6&&ibe.D6!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          D7 Trap Door:
          <img v-if="ibe.D7" class="image_light"/><img v-if="!ibe.D7&&ibe.D7!==null" class="image_offlight"/>
        </td>
        <td>
          BDC1 EBOPS AC Supply:
          <img v-if="ibe.BDC1" class="image_light"/><img v-if="!ibe.BDC1&&ibe.BDC1!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          BDC2 EBOPS DC Supply:
          <img v-if="ibe.BDC2" class="image_light"/><img v-if="!ibe.BDC2&&ibe.BDC2!==null" class="image_offlight"/>
        </td>
        <td>
          BDC3 EBOPS Charging Supply:
          <img v-if="ibe.BDC3" class="image_light"/><img v-if="!ibe.BDC3&&ibe.BDC3!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          BDC4 EBOPS Charger Card:
          <img v-if="ibe.BDC4" class="image_light"/><img v-if="!ibe.BDC4&&ibe.BDC4!==null" class="image_offlight"/>
        </td>
        <td>
          BDA1 Motor Temperature:
          <img v-if="ibe.BDA1" class="image_light"/><img v-if="!ibe.BDA1&&ibe.BDA1!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          BDA2 Motor Noise:
          <img v-if="ibe.BDA2" class="image_light"/><img v-if="!ibe.BDA2&&ibe.BDA2!==null" class="image_offlight"/>
        </td>
        <td>
          BDA3 Motor Vibration:
          <img v-if="ibe.BDA3" class="image_light"/><img v-if="!ibe.BDA3&&ibe.BDA3!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          BDA4 Resistor Temperature:
          <img v-if="ibe.BDA4" class="image_light"/><img v-if="!ibe.BDA4&&ibe.BDA4!==null" class="image_offlight"/>
        </td>
        <td>
          BDA5 Sound Level:
          <img v-if="ibe.BDA5" class="image_light"/><img v-if="!ibe.BDA5&&ibe.BDA5!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          BDA6 Temperature:
          <img v-if="ibe.BDA6" class="image_light"/><img v-if="!ibe.BDA6&&ibe.BDA6!==null" class="image_offlight"/>
        </td>
        <td>
          BDA7 L Door Panel:
          <img v-if="ibe.BDA7" class="image_light"/><img v-if="!ibe.BDA7&&ibe.BDA7!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          BDA8 L Cam Roller:
          <img v-if="ibe.BDA8" class="image_light"/><img v-if="!ibe.BDA8&&ibe.BDA8!==null" class="image_offlight"/>
        </td>
        <td>
          BDA9 Car Cam Roller:
          <img v-if="ibe.BDA9" class="image_light"/><img v-if="!ibe.BDA9&&ibe.BDA9!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          BDA10 Trap Passenger:
          <img v-if="ibe.BDA10" class="image_light"/><img v-if="!ibe.BDA10&&ibe.BDA10!==null" class="image_offlight"/>
        </td>
        <td>
          BDA11 Car Noise:
          <img v-if="ibe.BDA11" class="image_light"/><img v-if="!ibe.BDA11&&ibe.BDA11!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          BDA12 Car Vibration:
          <img v-if="ibe.BDA12" class="image_light"/><img v-if="!ibe.BDA12&&ibe.BDA12!==null" class="image_offlight"/>
        </td>
        <td>
          BDA13 Gyroscope/ Tilt:
          <img v-if="ibe.BDA13" class="image_light"/><img v-if="!ibe.BDA13&&ibe.BDA13!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          BDA14 Lighting:
          <img v-if="ibe.BDA14" class="image_light"/><img v-if="!ibe.BDA14&&ibe.BDA14!==null" class="image_offlight"/>
        </td>
        <td>
          BDA15 Smoking Detection:
          <img v-if="ibe.BDA15" class="image_light"/><img v-if="!ibe.BDA15&&ibe.BDA15!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          BDA16 Guide Rail Rollers:
          <img v-if="ibe.BDA16" class="image_light"/><img v-if="!ibe.BDA16&&ibe.BDA16!==null" class="image_offlight"/>
        </td>
        <td>
          BDA17 Guide Lubrication Level:
          <img v-if="ibe.BDA17" class="image_light"/><img v-if="!ibe.BDA17&&ibe.BDA17!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          BDA18 Buffer Lubrication Level:
          <img v-if="ibe.BDA18" class="image_light"/><img v-if="!ibe.BDA18&&ibe.BDA18!==null" class="image_offlight"/>
        </td>
        <td>
          BDA19 Buffer Pressure:
          <img v-if="ibe.BDA19" class="image_light"/><img v-if="!ibe.BDA19&&ibe.BDA19!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          MA1 Motor Phase Voltage:
          <span class="value">{{ibe.MA1}} V</span>
        </td>
        <td>
          MA2 Motor Phase Current:
          <span class="value">{{ibe.MA2}} A</span>
        </td>
      </tr>
      <tr>
        <td>
          MA3 Motor Frequency:
          <span class="value">{{ibe.MA3}} Hz</span>
        </td>
        <td>
          MA4 Motor Speed:
          <span class="value">{{ibe.MA4}} r/min</span>
        </td>
      </tr>
      <tr>
        <td>
          MA5 Motor Torque:
          <span class="value">{{ibe.MA5}} N.m</span>
        </td>
        <td>
          MA6 Drive DC Voltage:
          <span class="value">{{ibe.MA6}} V</span>
        </td>
      </tr>
      <tr>
        <td>
          MD1 Control Circuit Supply:
          <img v-if="ibe.MD1" class="image_light"/><img v-if="!ibe.MD1&&ibe.MD1!==null" class="image_offlight"/>
        </td>
        <td>
          MD2 Circuit Continuity:
          <img v-if="ibe.MD2" class="image_light"/><img v-if="!ibe.MD2&&ibe.MD2!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          MD3 Abnormal Start/ Stops:
          <img v-if="ibe.MD3" class="image_light"/><img v-if="!ibe.MD3&&ibe.MD3!==null" class="image_offlight"/>
        </td>
        <td>
          MD4 Cyclic Acceleration:
          <img v-if="ibe.MD4" class="image_light"/><img v-if="!ibe.MD4&&ibe.MD4!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          MD5 Cyclic Deceleration:
          <img v-if="ibe.MD5" class="image_light"/><img v-if="!ibe.MD5&&ibe.MD5!==null" class="image_offlight"/>
        </td>
        <td>
          MD6 Drive Time:
          <span class="value">{{ibe.MD6}} min</span>
        </td>
      </tr>
      <tr>
        <td>
          MD7 Motor Start/ Stop:
          <img v-if="ibe.MD7" class="image_light"/><img v-if="!ibe.MD7&&ibe.MD7!==null" class="image_offlight"/>
        </td>
        <td>
          MD8 LD Open:
          <img v-if="ibe.MD8" class="image_light"/><img v-if="!ibe.MD8&&ibe.MD8!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          MD9 LD Close:
          <img v-if="ibe.MD9" class="image_light"/><img v-if="!ibe.MD9&&ibe.MD9!==null" class="image_offlight"/>
        </td>
        <td>
          MD10 LD Cycle:
          <span class="value">{{ibe.MD10}}</span>
        </td>
      </tr>
      <tr>
        <td>
          MD11 LD Hold: <img v-if="ibe.MD11" class="image_light"/><img v-if="!ibe.MD11&&ibe.MD11!==null" class="image_offlight"/>
        </td>
        <td>
          MD12 LD Interlock Switch:
          <img v-if="ibe.MD12" class="image_light"/><img v-if="!ibe.MD12&&ibe.MD12!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          MD13 Open:
          <img v-if="ibe.MD13" class="image_light"/><img v-if="!ibe.MD13&&ibe.MD13!==null" class="image_offlight"/>
        </td>
        <td>
          MD14 Close:
          <img v-if="ibe.MD14" class="image_light"/><img v-if="!ibe.MD14&&ibe.MD14!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          MD15 Hold:
          <img v-if="ibe.MD15" class="image_light"/><img v-if="!ibe.MD15&&ibe.MD15!==null" class="image_offlight"/>
        </td>
        <td>
          MD16 Waiting Time:
          <span class="value">{{ibe.MD16}} s</span>
        </td>
      </tr>
      <tr>
        <td>
          MD17 Fault:
          <img v-if="ibe.MD17" class="image_light"/><img v-if="!ibe.MD17&&ibe.MD17!==null" class="image_offlight"/>
        </td>
        <td>
          MD18 Car Communication:
          <img v-if="ibe.MD18" class="image_light"/><img v-if="!ibe.MD18&&ibe.MD18!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          MD19 Loading:
          <img v-if="ibe.MD19" class="image_light"/><img v-if="!ibe.MD19&&ibe.MD19!==null" class="image_offlight"/>
        </td>
        <td>
          MD20 Level/ Position:
          <span class="value">{{ibe.MD20}}</span>
        </td>
      </tr>
      <tr>
        <td>
          MD21 Movement Up:
          <img v-if="ibe.MD21" class="image_light"/><img v-if="!ibe.MD21&&ibe.MD21!==null" class="image_offlight"/>
        </td>
        <td>
          MD22 Movement Down:
          <img v-if="ibe.MD22" class="image_light"/><img v-if="!ibe.MD22&&ibe.MD22!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          MD23 Hall Button:
          <img v-if="ibe.MD23" class="image_light"/><img v-if="!ibe.MD23&&ibe.MD23!==null" class="image_offlight"/>
        </td>
        <td>
          MD24 Hall Indicator:
          <img v-if="ibe.MD24" class="image_light"/><img v-if="!ibe.MD24&&ibe.MD24!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          MD25 Brake On/ Off:
          <span class="value">{{ibe.MD25}}</span>
        </td>
        <td>
          MD26 Brake Interval Time:
          <span class="value">{{ibe.MD26}} s</span>
        </td>
      </tr>
      <tr>
        <td>
          BDA20 Lift Breakdown 24/7:
          <img v-if="ibe.BDA20" class="image_light"/><img v-if="!ibe.BDA20&&ibe.BDA20!==null" class="image_offlight"/>
        </td>
        <td>
          BDA21 Jerkiness:
          <img v-if="ibe.BDA21" class="image_light"/><img v-if="!ibe.BDA21&&ibe.BDA21!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          BDA22 Irregular Acceleration:
          <img v-if="ibe.BDA22" class="image_light"/><img v-if="!ibe.BDA22&&ibe.BDA22!==null" class="image_offlight"/>
        </td>
        <td>
          BDA23 Irregular Velocity:
          <img v-if="ibe.BDA23" class="image_light"/><img v-if="!ibe.BDA23&&ibe.BDA23!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          BDA24 Numbers of Vibration:
          <span class="value">{{ibe.BDA24}}</span>
        </td>
        <td>
          BDA25 Number of Trips:
          <span class="value">{{ibe.BDA25}}</span>
        </td>
      </tr>
      <tr>
        <td>
          BDA26 Trip patterns by Floor, Time and Day:
          <table class="vm-table" style="width: 100%;margin-top: 10px">
            <tr style="height: 30px">
              <td style="width: 14%;">One</td>
              <td style="width: 14%;">Two</td>
              <td style="width: 14%;">Three</td>
              <td style="width: 14%;">Four</td>
              <td style="width: 14%;">Five</td>
              <td style="width: 14%;">Six</td>
              <td style="width: 14%;">Seven</td>
            </tr>
            <tr v-if="ibe.BDA26!==null && ibe.BDA26" style="height: 30px">
              <td style="text-align: center">{{ibe.BDA26[0]}}</td>
              <td style="text-align: center">{{ibe.BDA26[1]}}</td>
              <td style="text-align: center">{{ibe.BDA26[2]}}</td>
              <td style="text-align: center">{{ibe.BDA26[3]}}</td>
              <td style="text-align: center">{{ibe.BDA26[4]}}</td>
              <td style="text-align: center">{{ibe.BDA26[5]}}</td>
              <td style="text-align: center">{{ibe.BDA26[6]}}</td>
            </tr>
            <tr v-else style="height: 30px">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </td>
        <td>
          BDA27 Distance Travelled:
          <span class="value">{{ibe.BDA27}} m</span>
        </td>
      </tr>
      <tr>
        <td>
          BDA28 Travel Time:
          <span class="value">{{ibe.BDA28}} min</span>
        </td>
        <td>
          BDA29 Idle Time:
          <span class="value">{{ibe.BDA29}} min</span>
        </td>
      </tr>
      <tr>
        <td>
          BDE1 MR Temperature:
          <img v-if="ibe.BDE1 " class="image_light"/><img v-if="!ibe.BDE1&&ibe.BDE1!==null" class="image_offlight"/>
        </td>
        <td>
          BDE2 MR Pressure:
          <img v-if="ibe.BDE2" class="image_light"/><img v-if="!ibe.BDE2&&ibe.BDE2!==null" class="image_offlight"/>
        </td>
      </tr>
      <tr>
        <td>
          BDE3 MR Humidity:
          <img v-if="ibe.BDE3" class="image_light"/><img v-if="!ibe.BDE3&&ibe.BDE3!==null" class="image_offlight"/>
        </td>
        <td>
          BDE4 Car-Urine Detection:
          <img v-if="ibe.BDE4" class="image_light"/><img v-if="!ibe.BDE4&&ibe.BDE4!==null" class="image_offlight"/>
        </td>
      </tr>
    </table>
  </el-drawer>
  <!--  </el-dialog>-->
</template>

<script>
  export default {
    props: {
      ibe: {
        type: Object,
      },
    },
    data() {
      return {
        drawer: false,
        dialogVisible: false,
        direction: "rtl",
      };
    },
    methods: {
      open() {
        this.drawer = true;
        this.dialogVisible = true;
      },
      handleClose(done) {
        done();
      },
    },
  };
</script>

<style lang="scss" scoped>
.value {
  float: right;
}

.image_light {
  content: url("/static/images/elevator/onlight.png");
  width: 20px;
  height: 20px;
  float: right;
  margin-left: 20px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.image_offlight {
  content: url("/static/images/elevator/offlight.png");
  width: 20px;
  height: 20px;
  float: right;
  margin-left: 20px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

table {
  td {
    text-align: left;
    padding: 5px 10px;
  }
}
</style>
