<template>
  <div class="monitor" :class="{'bg-animation': hb.s_run===1, 'animation-reverse': hb.s_direction===1}">
    <div class="car">
      <div class="floor">
        <img v-if="hb.s_direction===1" :src="'/static/images/sensorMonitor/lift/arrow_up.'+(hb.s_run===1?'gif':'png')"/>
        <img
          v-else-if="hb.s_direction===2"
          :src="'/static/images/sensorMonitor/lift/arrow_down.'+(hb.s_run===1?'gif':'png')"/>
        <span class="text">{{hb.s_floor_show || hb.s_floor_real}}</span>
      </div>
      <div class="door" :class="doorStatus===-1?'':doorStatus?'door-open':'door-close'">
      </div>
    </div>
    <div v-if="wsShowTip" class="em-connect-status-wrapper">
      <div class="em-connect-status">
        <div class="tip">
          <img class="icon" src="/static/images/sensorMonitor/loading.gif"/>
          <span class="text">{{wsTipText}}</span>
        </div>
        <el-button
          v-if="wsShowRetry"
          class="retry"
          type="primary"
          icon="el-icon-refresh"
          @click="createWs">
          {{$t("monitor.retry")}}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
  import WebsocketHeartbeatJs from "websocket-heartbeat-js";

  export default {
    components: {},
    props: {
      regCode: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        elevator: {},
        ws: null,
        wsShowTip: true,
        wsShowRetry: false,
        wsTipText: this.$t("monitor.escalatorConnect"),
        hb: {
          s_direction: 0, //0：无方向 1：上行 2：下行
          s_floor_real: "",
          s_floor_show: "",
          s_run: "",
          s_door1: 0,
        },
      };
    },
    computed: {
      doorStatus: function() {
        let status = this.hb.s_door1;
        //未知
        if (status === 0) {
          return -1;
        }
        //开门
        if (status === 3 || status === 4 || status === 6) {
          return 1;
        }
        return 0;
      },
    },
    mounted() {
      this.createWs();
    },
    destroyed() {
      if (this.ws) {
        this.ws.close();
      }
    },
    methods: {
      createWs() {
        try {
          const options = {
            url: window.config.wsUrl,
            pingTimeout: 30000,
            reconnectTimeout: 5000,
            repeatLimit: 5,
            pingMsg: "{\"t\": \"B01\"}",
          };
          this.wsShowTip = true;
          this.wsTipText = this.$t("monitor.escalatorConnect");
          this.ws = new WebsocketHeartbeatJs(options);
          this.initWsEvent();
        } catch (e) {
          this.wsTipText = this.$t("monitor.error");
          this.showRetry();
        }
      },
      initWsEvent() {
        this.ws.onopen = () => {
          console.log("onopen");
          let authProto = {
            t: "B03",
            d: {regCode: this.regCode},
          };
          this.ws.send(JSON.stringify(authProto)); // 发送认证信息
        };
        this.ws.onclose = () => {
          console.log("onclose");
          this.showRetry(this.$t("monitor.error"));
        };
        this.ws.onerror = () => {
          console.log("onerror");
          this.showRetry(this.$t("monitor.error"));
        };
        this.ws.onreconnect = () => {
          console.log("onreconnect");
          this.wsShowTip = true;
          this.wsTipText = this.$t("monitor.escalatorConnect");
        };
        this.ws.onmessage = (e) => {
          console.log("msg:", e.data);
          try {
            let proto = JSON.parse(e.data);
            let type = proto.t;
            switch (type) {
              case "A02":
                this.showRetry(this.$t("monitor.error"));
                break;
              case "A06":
                this.showRetry(this.$t("monitor.a06Describe"));
                break;
              case "A08":
                this.showRetry(this.$t("monitor.a08Describe"));
                break;
              case "A10":
                this.showRetry(this.$t("monitor.a10Describe"));
                break;
              case "A11":
                this.showRetry(this.$t("monitor.a11Describe"));
                break;
              case "HA":
              case "HC":
                this.$emit("status-change", proto);
                this.wsShowTip = false;
                break;
              case "HB":
                this.hb = JSON.parse(JSON.stringify(proto.d));
                this.$emit("status-change", proto);
                this.wsShowTip = false;
                break;
              case "HD":
                let hd = JSON.parse(JSON.stringify(proto.d));
                if (hd.result === 0) {
                  this.$message.info("终端收到命令，可执行，但未完成");
                } else if (hd.result === 1) {
                  this.$message.info("终端收到命令，且已完成");
                } else if (hd.result === 2) {
                  this.$message.info("终端收到命令，不可执行");
                } else if (hd.result === 3) {
                  this.$message.info("终端收到命令，执行失败");
                }
                this.wsShowTip = false;
                break;
            }
          } catch (e) {
            console.log(e);
          }
        };
      },
      showRetry(msg) {
        this.wsShowTip = true;
        this.wsShowRetry = true;
        this.ws.close();
        if (msg) {
          this.wsTipText = msg;
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
.monitor {
  position: relative;
  width: 440px;
  height: 586px;
  background-image: url(/static/images/sensorMonitor/lift/bg.jpg);
  background-size: cover;

  .car {
    transform: scale(0.8, 0.8);
    width: 438px;
    height: 556px;
    background-image: url(/static/images/sensorMonitor/lift/car.png);
    background-size: cover;
  }

  .floor {
    transform: rotate(2deg);
    width: 20px;
    justify-content: center;
    color: red;
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    top: 137px;
    left: 140px;
    display: flex;

    .text {
      margin-left: 5px;
    }
  }

  .door {
    width: 100%;
    height: 100%;
    background: url(/static/images/sensorMonitor/lift/door_status.png) no-repeat 0 0;
  }

  .door-open {
    animation: door-open-animate 1s steps(8, start);
    -moz-animation: door-open-animate 1s steps(8, start);
    animation-fill-mode: forwards;
  }

  @keyframes door-open-animate {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -3504px 0;
    }
  }

  .door-close {
    animation: door-close-animate 1s steps(8, start);
    -moz-animation: door-close-animate 1s steps(8, start);
  }

  @keyframes door-close-animate {
    from {
      background-position: -3504px 0;
    }
    to {
      background-position: 0 0;
    }
  }

  @keyframes animatedBackgroundDown {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 0 -1196px;
    }
  }

  .em-connect-status-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;

    .em-connect-status {
      position: absolute;
      text-align: center;

      .tip {
        display: flex;
        align-items: center;

        .icon {
          width: 60px;
          background-color: rgba(164, 243, 145, 0.1);
          padding: 5px;
          border: 1px solid white;
          border-radius: 50%;
        }

        .text {
          color: white;
          font-size: 30px;
          margin-left: 10px;
        }
      }

      .retry {
        margin-top: 20px;
      }
    }

  }
}

.bg-animation {
  animation: animatedBackgroundDown 6s linear infinite;
  -moz-animation: animatedBackgroundDown 6s linear infinite;
}

.animation-reverse {
  animation-direction: reverse !important;
  -moz-animation-direction: reverse !important;
}

</style>
