<template>
  <div>
    <div
      class="animate-area"
      :style="{ animation: animation, '-ms-animation': animation, '-moz-animation':animation,'-webkit-animation':animation}">
      <div
        style="font-size: 20px;z-index: 100; position: absolute; top: 0; background:rgba(255,255,255,0.6); width:75%; height: 45px; line-height: 45px">
        {{name}}
      </div>
      <el-button
        style="position: absolute; top: 45px; left:0px;"
        type="primary"
        :icon="isSilence ? 'el-icon-bell':'el-icon-close-notification'"
        @click="changeMute">
      </el-button>
      <div class="elevatorBox">
        <img src="/static/images/elevator/ebox.png"/>
        <div class="layerArrow">
          <img v-if="srcDownUp" :src="srcDownUp"/>
        </div>
        <div class="layerNum">
          <div style="z-index: 23">
            <span>{{Car_Position}}</span>
          </div>
        </div>
        <div
          v-if="connectState === '连接断开' || tValue === 'A08' || tValue === 'A10' ||tValue === 'A06' || tValue === 'A05' || tValue === 'A11'"
          class="brokenClass">
          <img src="/static/images/elevator/broken1/brokenbg.png"/>
        </div>
        <div v-if="connectState === '连接断开'" class="unlinkClass">
          <img src="/static/images/elevator/broken1/unlink.png"/>
        </div>
        <div v-if="tValue === 'A08'">
          <span class="tValueClass">
            {{$t("monitor.a08Describe")}}
          </span>
        </div>
        <div v-if="tValue === 'A10'">
          <span class="tValueClass">
            {{$t("monitor.a10Describe")}}
          </span>
        </div>
        <div v-if="tValue === 'A11'">
          <span class="tValueClass">
            {{$t("monitor.a11Describe")}}
          </span>
        </div>
        <div v-if="tValue === 'A06'">
          <span class="tValueClass">
            {{$t("monitor.a06Describe")}}
          </span>
        </div>
        <div v-if="tValue === 'A05'">
          <span class="tValueClass">
            {{$t("monitor.a05Describe")}} {{beforeNum}}
          </span>
        </div>
        <div v-if="tValue === 'A04'">
          <img class="LoadingClass" src="/static/images/elevator/loading3.gif"/>
        </div>
        <div v-if="connectState === '连接断开'">
          <span class="statusName">
            {{$t("monitor.connectionDisconnection")}}
          </span>
        </div>
        <div class="door" :class="Door_Switch_Status?'door-open':'door-close'">
        </div>
      </div>
    </div>
    <!--电梯故障-->
    <div v-show="faultImg" class="elevatorBox1" style="z-index: 24">
      <img src="/static/images/elevator/ebox_alarmrd.png"/>
      <audio
        v-if="faultImg && !isSilence"
        id="faultMp3"
        src="/static/audio/Alarm.mp3"
        loop="loop"
        autoplay></audio>
    </div>
    <!--电梯故障+困人-->
    <div v-show="passengerImg" class="elevatorBox1" style="z-index: 24">
      <img src="/static/images/elevator/ebox_insbrted.png"/>
      <audio
        v-if="passengerImg && !isSilence"
        id="passengerMp3"
        src="/static/audio/Alarm.mp3"
        autoplay
        loop="loop"></audio>
    </div>
    <!--警铃报警+困人-->
    <div v-show="alarmBellImg" class="elevatorBox1" style="z-index: 24">
      <img src="/static/images/elevator/ebox_insbrted.png"/>
    </div>
  </div>
</template>
<script>
  export default {
    components: {},
    /* eslint-disable */
    props: [
      "name",
      "Car_Direction",
      "Car_Position",
      "Door_Zone",
      "Door_Status",
      "Door_Switch_Status",
      "Car_Status",
      "connectState",
      "tValue",
      "beforeNum",
      "Fault_Status",
      "passengerStatus",
      "alarmBellStatus",
    ],
    data() {
      return {
        isSilence: false,
        loading: false,
        faultImg: false,
        passengerImg: false,
        alarmBellImg: false,
        elevatorImg: true,
        tableData: [],
        srcDownUp: "",
        audioUrl: "",
        srcElevatorBox: "",
        animation: "animatedBackgroundStop 5s linear infinite",
        faultInterval: "",
        passengerInterval: "",
        alarmBellInterval: "",
      };
    },
    watch: {
      "Car_Direction": "changeUpDown",
      "Car_Status": "changeUpDown",
      "Fault_Status": "changeFaultStatus",
      "passengerStatus": "changePassengerStatus",
      "alarmBellStatus": "changeAlarmBellStatus",
    },
    methods: {
      changeMute() {
        this.isSilence = !this.isSilence;
      },
      changeUpDown() {
        if (this.Car_Status === 0) {
          if (this.Car_Direction === 1) {
            this.srcDownUp = "/static/images/elevator/arrow_up.png";
            this.animation = "animatedBackgroundStop 5s linear infinite";
          } else if (this.Car_Direction === 2) {
            this.srcDownUp = "/static/images/elevator/arrow_down.png";
            this.animation = "animatedBackgroundStop 5s linear infinite";
          } else {
            this.srcDownUp = "";
            this.animation = "animatedBackgroundStop 5s linear infinite";
          }
        } else {
          if (this.Car_Direction === 1) {
            this.srcDownUp = "/static/images/elevator/arrow_up.gif";
            this.animation = "animatedBackgroundUp 5s linear infinite";
          } else if (this.Car_Direction === 2) {
            this.srcDownUp = "/static/images/elevator/arrow_down.gif";
            this.animation = "animatedBackgroundDown 5s linear infinite";
          } else {
            this.srcDownUp = "";
            this.animation = "animatedBackgroundStop 5s linear infinite";
          }
        }
      },
      changeFaultStatus() {
        if (this.faultInterval === "") {
          this.faultInterval = setInterval(() => {
            if (this.Fault_Status && !this.passengerStatus) {
              this.faultImg = !this.faultImg;
            } else {
              let musicMp3 = document.getElementById("faultMp3");
              if (musicMp3) {
                musicMp3.pause();
                //clearInterval(this.faultInterval);
                this.faultImg = false;
              }
            }
          }, 600);
        }
      },
      changePassengerStatus() {
        if (this.passengerInterval === "") {
          this.passengerInterval = setInterval(() => {
            if (this.Fault_Status && this.passengerStatus) {
              this.passengerImg = !this.passengerImg;
              console.log("changePassengerStatus");
            } else {
              let musicMp3 = document.getElementById("passengerMp3");
              if (musicMp3) {
                musicMp3.pause();
                //clearInterval(this.passengerInterval);
                this.passengerImg = false;
              }
            }
          }, 600);
        }
      },
      changeAlarmBellStatus() {
        if (this.alarmBellInterval === "") {
          this.alarmBellInterval = setInterval(() => {
            if (this.alarmBellStatus && !(this.Fault_Status)) {
              this.alarmBellImg = !this.alarmBellImg;
              console.log("changeAlarmBellStatus");
            } else {
              //clearInterval(this.alarmBellInterval);
              this.alarmBellImg = false;
            }
          }, 600);
        }
      },
    },
  };
</script>
<style type="text/css">
.animate-area {
  position: relative;
  width: 650px;
  height: 650px;
  background-image: url(/static/images/elevator/bg2.jpg);
  background-size: 100%;
  background-position: 0px 0px;
  background-repeat: repeat-y;
  animation: animatedBackgroundDown 5s linear infinite;
  -ms-animation: animatedBackgroundDown 5s linear infinite;
  -moz-animation: animatedBackgroundDown 5s linear infinite;
  -webkit-animation: animatedBackgroundDown 5s linear infinite;
}

@keyframes animatedBackgroundDown {
  from {
    background-position: 0 0px;
  }
  to {
    background-position: 0 100%;
  }
}

@keyframes animatedBackgroundUp {
  from {
    background-position: 0 0px;
  }
  to {
    background-position: 0 -100%;
  }
}

@keyframes animatedBackgroundStop {
  from {
    background-position: 0 0px;
  }
  to {
    background-position: 0 0%;
  }
}

.elevatorBox {
  position: absolute;
  width: 440px;
  height: 580px;
  top: 8%;
  bottom: 0;
  left: -150px;
  right: 0;
  margin: auto;
}

.elevatorBox1 {
  position: absolute;
  width: 440px;
  height: 580px;
  top: 18%;
  left: 40px;
}

.layerArrow {
  position: absolute;
  z-index: 21;
  width: 20px;
  height: 20px;
  top: 23.5%;
  left: 29%;
}

.layerNum {
  position: absolute;
  z-index: 22;
  top: 23.5%;
  left: 34%;
  color: crimson;
  font-size: 20px;
}

a {
  text-decoration: none;
  color: #000;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.box_img {
  width: 143px;
  height: 294px;
  overflow: hidden;
  margin: 5px 0px 0px 3px;
}

.box_img ul li {
  position: absolute;
}

.box_img ul li a {
  display: block;
  width: 143px;
  height: 294px;
}

.Status {
  position: absolute;
  z-index: 24;
  width: 20px;
  height: 20px;
  top: 30%;
  left: 20%;
}

.statusName {
  position: absolute;
  z-index: 24;
  top: 45%;
  left: 20%;
  font-size: 30px;
  font-family: "微软雅黑";
  color: #ffa473;
}

.tValueClass {
  position: absolute;
  z-index: 24;
  top: 57%;
  left: 20%;
  font-size: 30px;
  color: #ffffff;
}

.LoadingClass {
  position: absolute;
  z-index: 24;
  top: 34%;
  left: 35%;
}

.brokenClass {
  position: absolute;
  z-index: 24;
  top: 40%;
  left: 5%;
}

.unlinkClass {
  position: absolute;
  z-index: 24;
  top: 46%;
  left: 10%;
}

.door {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 23;
  background: url(/static/images/sensorMonitor/lift/door_status.png) no-repeat 0 0;
}

.door-open {
  animation: door-open-animate 1s steps(8, start);
  -moz-animation: door-open-animate 1s steps(8, start);
  animation-fill-mode: forwards;
}

@keyframes door-open-animate {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -3504px 0;
  }
}

.door-close {
  animation: door-close-animate 1s steps(8, start);
  -moz-animation: door-close-animate 1s steps(8, start);
}

@keyframes door-close-animate {
  from {
    background-position: -3504px 0;
  }
  to {
    background-position: 0 0;
  }
}

</style>
