<template>
  <el-dialog
    :title="$t('faultTemplate.solution') + '-' + $t('faultTemplate.faultCode') + '：' + faultCode + '-' + faultDesc"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="700px"
    top="5vh"
    append-to-body
    class="small-padding"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-table :data="tableData" style="width: 100%" height="calc(100vh - 25vh)">
        <!--    <el-table-column prop="freason" :label="$t('faultTemplate.reason')"/>-->
        <el-table-column prop="fsolution" :label="$t('faultTemplate.solution')"></el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        elevatorId: 0,
        faultCode: "",
        faultDesc: "",
        faultType: 0,
        tableData: [],
      };
    },
    methods: {
      open(elevatorId, faultCode, faultDesc, faultType) {
        this.elevatorId = elevatorId;
        this.faultCode = faultCode;
        this.faultDesc = faultDesc;
        this.faultType = faultType;
        this.dialogVisible = true;
        this.getList();
      },
      getList() {
        this.contentLoading = true;
        let params = {
          faultCode: this.faultCode,
          faultType: this.faultType,
        };
        this.$http.get("solutionSettings/solution/" + this.elevatorId, params).then(res => {
          this.contentLoading = false;
          this.tableData = res.data;
          console.log(this.tableData);
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onDialogClose() {
        this.contentLoading = false;
        this.dialogVisible = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
